@import "./utils.less";
@import "./scroll.less";
@import "./fonts.less";
// @import "~tailwindcss/base.css";
// @import "~tailwindcss/components.css";
@import "~tailwindcss/utilities.css";

html * {
  font-family: "Kanit" !important;
}

body {
  font-size: 16px;
}

html,
body,
#root {
  min-height: 100vh;
  min-width: 100vw;
}
body {
  margin: 0px;
}

.ant-typography {
  margin: 0px !important;
  // color: white;
}

svg * {
  // fill: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: all 100000s ease-in-out 0s;
  // transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}
.image-wrapper {
  position: relative;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}